<template>
    <div class="bg-scorelitgray md:rounded-md w-full px-4 md:px-8 pt-4 md:pt-6 pb-3 mb-6">
        <div class="text-gray-400 text-sm">Date</div>
            <div class="divide-y divide-gray-500">
                <div v-for="transaction in transactionGroup" :key="transaction.id" class="flex justify-between py-3 items-center">
                    <div class="inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 sm:h-5 sm:w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                        </svg>
                    <div class="text-white font-medium w-40 sm:w-full">{{ transaction.description }} - {{ transaction.user }}</div>
                </div>
            <div class="text-white font-medium text-md">+ {{ transaction.amount }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TransactionItem',
    props: ['transactionGroup'],
}
</script>
