<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 @click="click" class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Transacties
                </h2>
                <div v-for="transactionGroup in groupedTransactions" :key="transactionGroup.key">
                    <TransactionItem :transactionGroup="transactionGroup"/>
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import TransactionItem from "@/components/rows/TransactionItem";

export default {
    name: "FinancialTransactions",
    components: {
        DashboardWrapper,
        TransactionItem,
    },

    data() {
        return {
            transactions: [
                { id: 1,    date: '8 Juni 2021',      description: 'Videoanalyse U22-1',     user: 'Jan',     amount: '24,95' },
                { id: 2,    date: '3 Juni 2021',      description: 'Videoanalyse U16-3',     user: 'Jan',     amount: '12,50' },
                { id: 3,    date: '3 Juni 2021',      description: 'Videoanalyse U16-1',     user: 'Jan',     amount: '30,00' },
                { id: 4,    date: '3 Juni 2021',      description: 'Videoanalyse U16-1',     user: 'Jan',     amount: '11,00' },
                { id: 5,    date: '30 Mei 2021',      description: 'Videoanalyse U20-2',     user: 'Jan',     amount: '14,75' },
            ],
        }
    },

    computed: {
        
        groupedTransactions(){
            const t = this.transactions.reduce((acc, cur) => {
                if (!acc.hasOwnProperty(cur.date)) {
                    acc[cur.date] = []
                }
                acc[cur.date].push(cur)
                return acc
            }, {})
            return t;
        },
    
    },

    methods: {
        click(){
            console.log(this.groupedTransactions);
        },
    }

}
</script>